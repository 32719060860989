import clsx from "classnames"
import React, { useState } from "react"
import { ImagePlaceholder } from "common/components/ImagePlaceholder"

export interface ImageProps {
  /** Image source URL. */
  src: string
  /** Image alternate text. */
  alt: string
  /** Caption for the video. */
  caption?: string
  /** URL to link to when the image is clicked. */
  href?: string
}

const Figure = ({ src, alt, caption }: ImageProps) => {
  const [imageError, setImageError] = useState(false)

  return (
    <figure className="my-4 max-w-screen-lg lg:my-9">
      <img
        className={clsx("w-full", { hidden: imageError })}
        src={src}
        alt={alt}
        loading="lazy"
        onError={() => setImageError(true)}
        onLoad={() => setImageError(false)}
      />
      {imageError && <ImagePlaceholder />}
      {caption && <figcaption className="mt-1 text-sm text-gray-700">{caption}</figcaption>}
    </figure>
  )
}

export const Image = (props: ImageProps) => {
  const { href } = props

  return href ? (
    <a href={href} target="_blank">
      <Figure {...props} />
    </a>
  ) : (
    <Figure {...props} />
  )
}
